import { useQuery } from "@tanstack/react-query";
import { client } from "@/lib/hono";
import { InferResponseType } from "hono";

export type GetCaptionsResponseType = InferResponseType<
    (typeof client.api.storage.captions)[":captionsId"]["$get"],
    200
>;

export const useGetCaptions = (captionsId: string) => {
    const query = useQuery({
        enabled: !!captionsId,
        queryKey: ["get-captions", captionsId],
        queryFn: async () => {
            const response = await client.api.storage.captions[
                ":captionsId"
            ].$get({ param: { captionsId } });

            if (!response.ok) {
                throw new Error("Failed to fetch captions");
            }

            const { data } = await response.json();
            return { data };
        },
    });

    return query;
};
