import { v4 as uuid } from "uuid";
import { Input } from "@/components/ui/input";
import { useInteractions } from "../../../hooks/interactionsProvider";
import { FormInteractionContent } from "@dev-xsiter/shared-schema";
import { PlusCircle, Trash } from "lucide-react";
import { T } from "@tolgee/react";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";

const MAX_OPTIONS = 4;

export default function FormFields() {
    const { selectedInteraction, updateInteraction } = useInteractions();

    if (!selectedInteraction || selectedInteraction.type !== "form")
        return <></>;

    const fields =
        (selectedInteraction?.content as FormInteractionContent)?.fields || [];

    const updateField = (
        id: string,
        fieldsToUpdate: Partial<FormInteractionContent["fields"][0]>
    ) => {
        updateInteraction(selectedInteraction!.id, {
            content: {
                ...selectedInteraction!.content,
                fields: (
                    selectedInteraction!.content as FormInteractionContent
                ).fields.map((o) =>
                    o.id === id ? { ...o, ...fieldsToUpdate } : o
                ),
            },
        });
    };

    const addField = () => {
        updateInteraction(selectedInteraction!.id, {
            content: {
                ...selectedInteraction?.content,
                fields: [
                    ...fields,
                    {
                        id: uuid(),
                        label: "",
                    },
                ],
            },
        });
    };

    const deleteField = (id: string) => {
        updateInteraction(selectedInteraction!.id, {
            content: {
                ...selectedInteraction!.content,
                fields: (
                    selectedInteraction!.content as FormInteractionContent
                ).fields.filter((o) => o.id !== id),
            },
        });
    };

    return (
        <div className="flex flex-col gap-2 mb-2">
            <div className="flex flex-row justify-between items-center">
                <Label>
                    <T
                        keyName="interaction-modal.form-fields.label"
                        defaultValue="שדות"
                    />
                </Label>

                {fields.length < MAX_OPTIONS && (
                    <Button
                        variant="link"
                        size="xs"
                        onClick={() => addField()}
                        type="button"
                        className="flex flex-row gap-1.5 items-center text-black h-fit px-0"
                    >
                        <PlusCircle className="size-3.5" />
                        <T
                            keyName="interaction-modal.form-fields.add-btn"
                            defaultValue="הוספת שדה"
                        />
                    </Button>
                )}
            </div>

            <div className="flex flex-col gap-2">
                {fields.map((f, idx) => (
                    <div key={idx} className="flex flex-row items-center">
                        <span className="w-5 text-sm text-muted-foreground">
                            {idx + 1}.
                        </span>
                        <Input
                            className="h-8 text-xs"
                            value={f.label}
                            onChange={(e) =>
                                updateField(f.id, {
                                    label: e.target.value,
                                })
                            }
                        />
                        <button onClick={() => deleteField(f.id)} type="button">
                            <Trash className="ms-2 text-destructive size-[16px]" />
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
}
