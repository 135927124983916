import {
    Sidebar,
    SidebarContent,
    SidebarGroup,
    SidebarGroupContent,
    SidebarMenu,
    SidebarMenuButton,
    SidebarMenuItem,
} from "@/components/ui/sidebar";
import { interactionModalSteps } from "../../lib/utils";
import { T } from "@tolgee/react";
import { useInteractions } from "../../hooks/interactionsProvider";
import { VideoInteractionType } from "@dev-xsiter/shared-schema";

export default function InteractionModalSidebar() {
    const { currentStep, moveToStep, selectedInteraction } = useInteractions();

    return (
        <Sidebar collapsible="none" className="flex border-e">
            <SidebarContent>
                <SidebarGroup>
                    <SidebarGroupContent>
                        <SidebarMenu>
                            {interactionModalSteps
                                .filter((item) => {
                                    if (
                                        !selectedInteraction ||
                                        !item.showForTypes
                                    ) {
                                        return true;
                                    } else if (
                                        !item.showForTypes.includes(
                                            selectedInteraction.type
                                        )
                                    ) {
                                        return false;
                                    } else {
                                        return true;
                                    }
                                })
                                .map((item) => (
                                    <SidebarMenuItem key={item.id}>
                                        <SidebarMenuButton
                                            asChild
                                            isActive={item.id === currentStep}
                                        >
                                            <button
                                                className="text-start"
                                                onClick={() =>
                                                    moveToStep(item.id)
                                                }
                                            >
                                                <item.icon />
                                                <T
                                                    keyName={
                                                        item
                                                            .uniqueTextPerType?.[
                                                            selectedInteraction?.type as VideoInteractionType
                                                        ]?.keyName ||
                                                        item.keyName
                                                    }
                                                    defaultValue={
                                                        item
                                                            .uniqueTextPerType?.[
                                                            selectedInteraction?.type as VideoInteractionType
                                                        ]?.defaultValue ||
                                                        item.defaultValue
                                                    }
                                                />
                                            </button>
                                        </SidebarMenuButton>
                                    </SidebarMenuItem>
                                ))}
                        </SidebarMenu>
                    </SidebarGroupContent>
                </SidebarGroup>
            </SidebarContent>
        </Sidebar>
    );
}
