"use client";

import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { T } from "@tolgee/react";
import { LoaderCircle, Plus } from "lucide-react";
import { useInteractions } from "./hooks/interactionsProvider";
import InteractionsList from "./interactionsList/interactionsList";

export default function InteractionsTab() {
    const { createInteraction, saveChanges, changes, loading } =
        useInteractions();

    return (
        <div className="mt-4">
            <div className="flex flex-row gap-2 justify-between items-center">
                <div>
                    <Label className="">
                        <T
                            keyName="video-sheet.interactions-tab.title"
                            defaultValue="אינטראקציות"
                        />
                    </Label>
                    <p className="text-sm text-muted-foreground">
                        <T
                            keyName="video-sheet.interactions-tab.description"
                            defaultValue="הפכו את הסרטון שלכם לאינטראקטיבי עם שאלות ואינטראקציות בתוך הנגן."
                        />
                    </p>
                </div>
                <Button
                    size="sm"
                    variant="outline"
                    className="flex flex-row items-center gap-1 w-fit"
                    onClick={createInteraction}
                >
                    <Plus className="size-4" />
                    <T
                        keyName="video-sheet.interactions-tab.save-btn"
                        defaultValue="אינטראקציה"
                    />
                </Button>
            </div>

            <div className="mt-6 flex flex-col">
                <InteractionsList />

                <Button
                    disabled={loading || !changes}
                    onClick={saveChanges}
                    className="w-fit"
                >
                    {loading ? (
                        <LoaderCircle className="size-4 animate-spin" />
                    ) : (
                        <T
                            keyName="video-sheet.interactions-tab.save-btn"
                            defaultValue="שמירה"
                        />
                    )}
                </Button>
            </div>
        </div>
    );
}
