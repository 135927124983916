"use client";

import CollectionsGrid from "@/app/(platform)/storage/components/grids/collectionsGrid";
import { useStorage } from "../useStorage";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
} from "@/components/ui/dialog";
import { T } from "@tolgee/react";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
} from "@/components/ui/select";
import { useState } from "react";
import FilesGrid from "@/app/(platform)/storage/components/grids/filesGrid";
import { GetCollectionsResponseType } from "@/app/(platform)/storage/hooks/useGetCollections";
import { ArrowRight } from "lucide-react";
import { Button } from "@/components/ui/button";
import { GetFilesResponseType } from "@/app/(platform)/storage/hooks/useGetFiles";

type SelectValue = "folders" | "files";

const selectOptions: {
    value: SelectValue;
    keyName: string;
    defaultValue: string;
}[] = [
    {
        value: "folders",
        keyName: "storage-explorer.select.folders",
        defaultValue: "תיקיות",
    },
    {
        value: "files",
        keyName: "storage-explorer.select.files",
        defaultValue: "קבצים",
    },
];

export default function StorageExplorerModal() {
    const [open, setOpen, options] = useStorage((state) => [
        state.explorerOpen,
        state.setExplorerOpen,
        state.explorerOptions,
    ]);

    const [selectValue, setSelectValue] = useState<SelectValue>("folders");
    const [selectedCollection, setSelectedCollection] = useState<
        GetCollectionsResponseType["data"][0] | null
    >(null);

    const defaultCollectionClick = (
        coll: GetCollectionsResponseType["data"][0]
    ) => {
        setSelectedCollection(coll);
        setSelectValue("files");
    };

    const defaultFileClick = (file: GetFilesResponseType["data"][0]) => {};

    return (
        <Dialog
            open={open}
            onOpenChange={(open: boolean) => {
                setOpen(open);
            }}
        >
            <DialogContent className="bg-muted flex flex-col min-w-[900px] min-h-[400px]">
                <div className="flex flex-row gap-3">
                    {selectedCollection && (
                        <Button
                            className="mt-1"
                            onClick={() => {
                                setSelectedCollection(null);
                                setSelectValue("folders");
                            }}
                            variant="outline"
                            size="icon"
                        >
                            <ArrowRight className="size-4" />
                        </Button>
                    )}
                    <DialogHeader className="mb-4">
                        <DialogTitle className="text-2xl font-semibold flex flex-row justify-between items-center gap-4">
                            <div className="flex flex-row gap-1.5 items-center">
                                {selectedCollection?.name || (
                                    <>
                                        <T
                                            keyName="storage-explorer.title"
                                            defaultValue="אחסון: "
                                        />
                                        {options.collections
                                            ?.onCollectionClick ? (
                                            <T
                                                keyName={
                                                    selectOptions.find(
                                                        (o) =>
                                                            o.value ===
                                                            "folders"
                                                    )!.keyName
                                                }
                                                defaultValue={
                                                    selectOptions.find(
                                                        (o) =>
                                                            o.value ===
                                                            "folders"
                                                    )!.defaultValue
                                                }
                                            />
                                        ) : (
                                            <Select
                                                dir="rtl"
                                                value={selectValue}
                                                onValueChange={(value) => {
                                                    setSelectedCollection(null);
                                                    setSelectValue(
                                                        value as SelectValue
                                                    );
                                                }}
                                            >
                                                <SelectTrigger className="p-0 bg-transparent border-none text-2xl font-semibold gap-1 outline-none focus:ring-0">
                                                    <div className="flex flex-row items-center gap-1">
                                                        <T
                                                            keyName={
                                                                selectOptions.find(
                                                                    (o) =>
                                                                        o.value ===
                                                                        selectValue
                                                                )!.keyName
                                                            }
                                                            defaultValue={
                                                                selectOptions.find(
                                                                    (o) =>
                                                                        o.value ===
                                                                        selectValue
                                                                )!.defaultValue
                                                            }
                                                        />
                                                    </div>
                                                </SelectTrigger>
                                                <SelectContent>
                                                    {selectOptions.map((o) => (
                                                        <SelectItem
                                                            key={o.value}
                                                            value={o.value}
                                                        >
                                                            <T
                                                                keyName={
                                                                    o.keyName
                                                                }
                                                                defaultValue={
                                                                    o.defaultValue
                                                                }
                                                            />
                                                        </SelectItem>
                                                    ))}
                                                </SelectContent>
                                            </Select>
                                        )}
                                    </>
                                )}
                            </div>
                        </DialogTitle>
                        <DialogDescription className="!mt-0 text-base">
                            {selectedCollection ? (
                                <>
                                    <T
                                        keyName="storage.folder"
                                        defaultValue="תיקייה"
                                    />{" "}
                                    ·{" "}
                                    <T
                                        keyName="storage.folder.num-of-files"
                                        defaultValue="{numOfFiles} קבצים"
                                        params={{
                                            numOfFiles:
                                                selectedCollection.fileCount,
                                        }}
                                    />
                                </>
                            ) : (
                                options.description && (
                                    <T
                                        keyName={options.description.keyName}
                                        defaultValue={
                                            options.description.defaultValue
                                        }
                                    />
                                )
                            )}
                        </DialogDescription>
                    </DialogHeader>
                </div>
                {selectValue === "folders"
                    ? options.collections && (
                          <CollectionsGrid
                              header={{
                                  options: {
                                      allowSearch: true,
                                      showCreateCollectionButton: true,
                                  },
                              }}
                              grid={{
                                  resultsPerPage: 8,
                                  itemOnClick: (coll) => {
                                      options?.collections?.onCollectionClick
                                          ? options.collections.onCollectionClick(
                                                coll
                                            )
                                          : defaultCollectionClick(coll);
                                  },
                                  setIsNoResults: (val) => {},
                              }}
                              footer={{
                                  showPagination: true,
                                  allowChangingResultsPerPage: false,
                                  justifyContent: "justify-end",
                              }}
                          />
                      )
                    : options.files && (
                          <FilesGrid
                              header={{
                                  options: {
                                      allowSearch: true,
                                      initialFilterByType: options.files.show,
                                  },
                              }}
                              grid={{
                                  resultsPerPage: 8,
                                  itemOnClick: (file) => {
                                      options?.files?.onFileClick
                                          ? options.files.onFileClick(file)
                                          : defaultFileClick(file);
                                  },
                                  setIsNoResults: (val) => {},
                                  collectionId: selectedCollection?.id,
                              }}
                              footer={{
                                  showPagination: true,
                                  allowChangingResultsPerPage: false,
                                  justifyContent: "justify-end",
                              }}
                          />
                      )}
            </DialogContent>
        </Dialog>
    );
}
