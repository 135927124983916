import { useInteractions } from "../../hooks/interactionsProvider";
import TimeAndTypeStep from "../steps/timeAndTypeStep";
import QuestionStep from "../steps/questionStep/questionStep";
import AnswersStep from "../steps/answersStep/answersStep";
import SettingsStep from "../steps/settingsStep/settingsStep";

export default function InteractionModalMain() {
    const { currentStep } = useInteractions();

    return (
        <main className="flex w-full h-full p-6">
            {currentStep === "time-and-type" && <TimeAndTypeStep />}
            {currentStep === "question" && <QuestionStep />}
            {currentStep === "answers" && <AnswersStep />}
            {currentStep === "settings" && <SettingsStep />}
        </main>
    );
}
