"use client";

import { Label } from "@/components/ui/label";
import { GetVideoResponseType } from "../../hooks/useGetVideo";
import { T } from "@tolgee/react";
import { Input } from "@/components/ui/input";
import { Checkbox } from "@/components/ui/checkbox";
import { useState } from "react";
import { Copy, Check } from "lucide-react";
import { formatIsoStringDate, getVideoPlayerEmbedUrl } from "@/lib/utils";

type EmbedOption = "responsive" | "autoplay" | "preload" | "loop" | "muted";

export default function VideoSheetAside({
    video,
    libraryId,
}: {
    video: GetVideoResponseType["data"];
    libraryId: number;
}) {
    const [embedOptions, setEmbedOptions] = useState<
        {
            id: EmbedOption;
            keyName: string;
            defaultValue: string;
            selected: boolean;
        }[]
    >([
        {
            id: "responsive",
            keyName: "video-sheet.embed-options.responsive.title",
            defaultValue: "רספונסיבי",
            selected: true,
        },
        {
            id: "autoplay",
            keyName: "video-sheet.embed-options.autoplay.title",
            defaultValue: "ניגון אוטומטי",
            selected: false,
        },
        {
            id: "muted",
            keyName: "video-sheet.embed-options.muted.title",
            defaultValue: "מושתק",
            selected: false,
        },
        {
            id: "loop",
            keyName: "video-sheet.embed-options.loop.title",
            defaultValue: "לופ",
            selected: false,
        },
        {
            id: "preload",
            keyName: "video-sheet.embed-options.preload.title",
            defaultValue: "טעינה מוקדמת",
            selected: false,
        },
    ]);
    const [isCopied1, setIsCopied1] = useState(false);
    const [isCopied2, setIsCopied2] = useState(false);

    const directPlayUrl = `https://app.yedatech.io/play/${video.pullZone}/${video.id}`;

    const generateEmbedLink = () => {
        const responsive = !!embedOptions.find((o) => o.id === "responsive")
            ?.selected;
        const autoplay = !!embedOptions.find((o) => o.id === "autoplay")
            ?.selected;
        const muted = !!embedOptions.find((o) => o.id === "muted")?.selected;
        const loop = !!embedOptions.find((o) => o.id === "loop")?.selected;
        const preload = !!embedOptions.find((o) => o.id === "preload")
            ?.selected;

        return `${getVideoPlayerEmbedUrl(
            video.pullZone!,
            video.id
        )}?autoplay=${autoplay}&loop=${loop}&muted=${muted}&preload=${preload}&responsive=${responsive}`;
    };

    const handleCopy1 = () => {
        if (isCopied1) return;
        navigator.clipboard.writeText(directPlayUrl);
        setIsCopied1(true);
        setTimeout(() => setIsCopied1(false), 2000);
    };

    const handleCopy2 = () => {
        if (isCopied2) return;
        navigator.clipboard.writeText(generateEmbedLink());
        setIsCopied2(true);
        setTimeout(() => setIsCopied2(false), 2000);
    };

    return (
        <aside className="flex flex-col w-full lg:w-[300px] xl:w-[400px] py-4 px-8 lg:pe-0 gap-4">
            <div className="bg-white flex flex-col p-4 rounded-md border w-full gap-6">
                <div>
                    <Label>
                        <T
                            keyName="video-sheet.aside.card.direct-play-link.title"
                            defaultValue="לינק לצפייה ישירה"
                        />
                    </Label>
                    <div className="relative mt-1">
                        <Input
                            disabled
                            className="!cursor-default !opacity-100 text-muted-foreground pe-9"
                            value={directPlayUrl}
                        />
                        <div
                            className="absolute inset-y-0 end-0 flex items-center pe-3 cursor-pointer"
                            onClick={handleCopy1}
                        >
                            {isCopied1 ? (
                                <Check className="size-4 text-green-500" />
                            ) : (
                                <Copy className="size-4 text-gray-500" />
                            )}
                        </div>
                    </div>
                </div>

                <div>
                    <Label>
                        <T
                            keyName="video-sheet.aside.card.embed.title"
                            defaultValue="קוד הטמעה"
                        />
                    </Label>
                    <div className="mt-1 grid grid-cols-3 gap-1">
                        {embedOptions.map((o) => (
                            <div
                                key={o.id}
                                className="flex flex-row items-center gap-1.5"
                            >
                                <Checkbox
                                    checked={
                                        embedOptions.find(
                                            (opt) => opt.id === o.id
                                        )?.selected
                                    }
                                    onCheckedChange={(checked) => {
                                        setEmbedOptions((prev) =>
                                            prev.map((opt) => ({
                                                ...opt,
                                                selected:
                                                    opt.id === o.id
                                                        ? !opt.selected
                                                        : opt.selected,
                                            }))
                                        );
                                    }}
                                />
                                <Label className="font-normal">
                                    <T
                                        keyName={o.keyName}
                                        defaultValue={o.defaultValue}
                                    />
                                </Label>
                            </div>
                        ))}
                    </div>
                    <div className="relative mt-3">
                        <Input
                            disabled
                            className="!cursor-default !opacity-100 text-muted-foreground pe-9"
                            value={generateEmbedLink()}
                        />
                        <div
                            className="absolute inset-y-0 end-0 flex items-center pe-3 cursor-pointer"
                            onClick={handleCopy2}
                        >
                            {isCopied2 ? (
                                <Check className="size-4 text-green-500" />
                            ) : (
                                <Copy className="size-4 text-gray-500" />
                            )}
                        </div>
                    </div>
                </div>

                <div>
                    <Label>
                        <T
                            keyName="video-sheet.aside.card.video-created-at.title"
                            defaultValue="תאריך העלאה"
                        />
                    </Label>
                    <div className="mt-1">
                        <Input
                            disabled
                            className="!cursor-default !opacity-100 text-muted-foreground pe-9"
                            value={formatIsoStringDate(
                                video.createdAt as string,
                                true
                            )}
                        />
                    </div>
                </div>
            </div>
        </aside>
    );
}
