import { useState } from "react";
import { GetVideoResponseType } from "../../hooks/useGetVideo";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { T } from "@tolgee/react";
import GeneralTab from "./tabs/general";
import CaptionsTab from "./tabs/captions/captions";
import ChaptersTab from "./tabs/chapters";
import { getVideoPlayerEmbedUrl } from "@/lib/utils";
import InteractionsTab from "./tabs/interactions/interactions";
import InteractionsProvider from "./tabs/interactions/hooks/interactionsProvider";

type TabValue = "general" | "captions" | "chapters" | "interactions";

export default function VideoSheetMain({
    video,
    uid,
    videoReady,
}: {
    video: GetVideoResponseType["data"];
    uid: string;
    videoReady: boolean;
}) {
    const [reloadVideoKey, setReloadVideoKey] = useState(0);
    const [tabs, setTabs] = useState<
        {
            value: TabValue;
            keyName: string;
            defaultValue: string;
            selected: boolean;
        }[]
    >([
        {
            value: "general",
            keyName: "video-sheet.tabs.general",
            defaultValue: "כללי",
            selected: true,
        },
        {
            value: "captions",
            keyName: "video-sheet.tabs.captions",
            defaultValue: "כתוביות",
            selected: false,
        },
        {
            value: "chapters",
            keyName: "video-sheet.tabs.chapters",
            defaultValue: "פרקים",
            selected: false,
        },
        {
            value: "interactions",
            keyName: "video-sheet.tabs.interactions",
            defaultValue: "אינטראקציות",
            selected: false,
        },
    ]);

    const videoUrl = getVideoPlayerEmbedUrl(video.pullZone!, video.id);
    //
    const currentTab = tabs.find((t) => t.selected);

    return (
        <main className="flex flex-1 flex-col py-4 px-8 gap-4">
            <div className="aspect-video rounded-md border relative">
                {videoReady ? (
                    <iframe
                        key={reloadVideoKey}
                        id="myVideo"
                        src={`${videoUrl}?t=${reloadVideoKey}&nocache=${reloadVideoKey}`}
                        className="rounded-md  w-full h-full border-none outline-none"
                        allowFullScreen
                        title="video"
                    />
                ) : (
                    <div className="flex flex-col items-center absolute inset-0 justify-center w-full h-full bg-gray-200 rounded-xl">
                        <svg
                            className="w-12 h-12 text-gray-400"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            viewBox="0 0 16 20"
                        >
                            <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
                            <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM9 13a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-2a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2Zm4 .382a1 1 0 0 1-1.447.894L10 13v-2l1.553-1.276a1 1 0 0 1 1.447.894v2.764Z" />
                        </svg>
                        <p className="mt-3 font-semibold">
                            <T
                                keyName="video-sheet.video-not-ready.title"
                                defaultValue="הסרטון נמצא בתהליך עיבוד"
                            />
                        </p>
                        <p className="text-sm text-muted-foreground">
                            <T
                                keyName="video-sheet.video-not-ready.subtitle"
                                defaultValue="תהליך העיבוד נמשך בדרך כלל כמה דקות אבל יכול להגיע גם לשעה"
                            />
                        </p>
                    </div>
                )}
            </div>
            <Tabs
                dir="rtl"
                value={currentTab?.value || "general"}
                onValueChange={(value: string) =>
                    setTabs((prev) =>
                        prev.map((t) => ({
                            ...t,
                            selected: t.value === value ? true : false,
                        }))
                    )
                }
            >
                <TabsList className="bg-white border w-full flex justify-start gap-2">
                    {tabs.map((t) => (
                        <TabsTrigger
                            className="data-[state=active]:bg-secondary"
                            key={t.value}
                            value={t.value}
                        >
                            <T
                                keyName={t.keyName}
                                defaultValue={t.defaultValue}
                            />
                        </TabsTrigger>
                    ))}
                </TabsList>

                {currentTab?.value === "general" && (
                    <GeneralTab video={video} uid={uid} />
                )}
                {currentTab?.value === "captions" && (
                    <CaptionsTab
                        videoId={video.id}
                        captionsId={video.captionsId || ""}
                        setReloadVideoKey={setReloadVideoKey}
                        videoReady={videoReady}
                        lengthInSeconds={video.length || 0}
                    />
                )}
                {currentTab?.value === "chapters" && (
                    <ChaptersTab
                        videoId={video.id}
                        initialChapters={video.chapters || []}
                        lengthInSeconds={video.length || 0}
                        setReloadVideoKey={setReloadVideoKey}
                    />
                )}
                {currentTab?.value === "interactions" && (
                    <InteractionsProvider
                        video={video}
                        setReloadVideoKey={setReloadVideoKey}
                    >
                        <InteractionsTab />
                    </InteractionsProvider>
                )}
                <div className="pb-8" />
            </Tabs>
        </main>
    );
}
