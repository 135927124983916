"use client";

import { useStorage } from "../../useStorage";
import { Sheet, SheetContent } from "@/components/ui/sheet";
import { useGetVideo } from "../hooks/useGetVideo";
import { LoaderCircle } from "lucide-react";
import VideoSheetHeader from "./components/header/header";
import VideoSheetAside from "./components/aside";
import VideoSheetMain from "./components/main";

export default function VideoSheet() {
    const [open, setOpen, options] = useStorage((state) => [
        state.videoSheetOpen,
        state.setVideoSheetOpen,
        state.videoOptions,
    ]);

    const { isPending, data } = useGetVideo(
        open && !!options.videoId && !!options.libraryId,
        options.videoId
    );
    const video = data?.data;

    const videoReady = video?.status === "ready";

    return (
        <Sheet open={open} onOpenChange={(open) => setOpen(open)}>
            <SheetContent
                className="bg-muted !p-0 w-full sm:w-[80vw] sm:max-w-[1200px]"
                xClassName="sm:top-4 sm:-left-10 sm:flex sm:justify-center sm:items-center sm:rounded-full sm:bg-white sm:size-6"
            >
                {isPending || !video ? (
                    <div className="flex w-full h-full justify-center items-center">
                        <LoaderCircle className="size-6 animate-spin" />
                    </div>
                ) : (
                    <>
                        <VideoSheetHeader
                            videoReady={videoReady}
                            video={video}
                        />

                        <div className="flex overflow-y-auto h-[calc(100%-64px)] flex-col lg:flex-row">
                            <VideoSheetAside
                                video={video}
                                libraryId={options.libraryId}
                            />
                            <VideoSheetMain
                                video={video}
                                uid={options.uid}
                                videoReady={videoReady}
                            />
                        </div>
                    </>
                )}
            </SheetContent>
        </Sheet>
    );
}
