import StepHeader from "../ui/stepHeader";
import { Label } from "@/components/ui/label";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import { VideoInteractionType } from "@dev-xsiter/shared-schema";
import { T } from "@tolgee/react";
import { useInteractions } from "../../hooks/interactionsProvider";
import { interactionTypeOptions } from "../../lib/utils";
import TimestampInput from "@/components/ui/timestamp-input";
import ContinueButton from "../ui/continueButton";

export default function TimeAndTypeStep() {
    const { selectedInteraction, updateInteraction } = useInteractions();

    if (!selectedInteraction) return <></>;

    return (
        <div className="flex flex-col gap-6 w-full">
            <StepHeader
                title={{
                    keyName:
                        "interaction-modal.time-and-type-step.header.title",
                    defaultValue: "זמן וסוג",
                }}
                description={{
                    keyName:
                        "interaction-modal.time-and-type-step.header.description",
                    defaultValue: "בחרו מתי האניטראקציה תופיע ואת הסוג שלה",
                }}
            />

            <div className="flex flex-col gap-2">
                <Label htmlFor="interactionTimestamp">
                    <T
                        keyName="interaction-modal.timestamp.label"
                        defaultValue="זמן הופעה"
                    />
                </Label>

                <TimestampInput
                    valueInSeconds={selectedInteraction.timestamp}
                    onValueChange={(value) =>
                        updateInteraction(selectedInteraction!.id, {
                            timestamp: value,
                        })
                    }
                    align="start"
                />
            </div>

            <div className="flex flex-col gap-2 w-full">
                <Label htmlFor="selectInteractionType">
                    <T
                        keyName="interaction-modal.select-type.label"
                        defaultValue="סוג"
                    />
                </Label>
                <Select
                    value={selectedInteraction?.type}
                    onValueChange={(value) =>
                        updateInteraction(selectedInteraction!.id, {
                            type: value as VideoInteractionType,
                        })
                    }
                    dir="rtl"
                >
                    <SelectTrigger className="h-9">
                        <SelectValue
                            placeholder="בחרו סוג"
                            placeholderKeyName="interaction-modal.select-type.placeholder"
                        />
                    </SelectTrigger>
                    <SelectContent>
                        {interactionTypeOptions.map((type) => (
                            <SelectItem key={type.id} value={type.id}>
                                <T
                                    keyName={type.keyName}
                                    defaultValue={type.defaultValue}
                                />
                            </SelectItem>
                        ))}
                    </SelectContent>
                </Select>
            </div>

            <ContinueButton />
        </div>
    );
}
