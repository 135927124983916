"use client";

import { Label } from "@/components/ui/label";
import { GetPresentationResponseType } from "../../hooks/useGetPresentation";
import { T } from "@tolgee/react";
import { Input } from "@/components/ui/input";
import { formatIsoStringDate } from "@/lib/utils";

export default function PresentationSheetAside({
    presentation,
}: {
    presentation: GetPresentationResponseType["data"];
}) {
    return (
        <aside className="flex flex-col w-full lg:w-[300px] xl:w-[400px] py-4 px-8 lg:pe-0 gap-4">
            <div className="bg-white flex flex-col p-4 rounded-md border w-full gap-6">
                <div>
                    <Label>
                        <T
                            keyName="presentation-sheet.aside.card.presentation-created-at.title"
                            defaultValue="נוצר בתאריך"
                        />
                    </Label>
                    <div className="mt-1">
                        <Input
                            disabled
                            className="!cursor-default !opacity-100 text-muted-foreground pe-9"
                            value={formatIsoStringDate(
                                presentation.createdAt as string,
                                true
                            )}
                        />
                    </div>
                </div>
                <div>
                    <Label>
                        <T
                            keyName="presentation-sheet.aside.card.presentation-updated-at.title"
                            defaultValue="עודכן לאחרונה"
                        />
                    </Label>
                    <div className="mt-1">
                        <Input
                            disabled
                            className="!cursor-default !opacity-100 text-muted-foreground pe-9"
                            value={formatIsoStringDate(
                                presentation.updatedAt as string,
                                true
                            )}
                        />
                    </div>
                </div>
                <div>
                    <Label>
                        <T
                            keyName="presentation-sheet.aside.card.num-of-slides.title"
                            defaultValue="מספר שקופיות"
                        />
                    </Label>
                    <div className="mt-1">
                        <Input
                            disabled
                            className="!cursor-default !opacity-100 text-muted-foreground pe-9"
                            value={presentation.numOfSlides}
                        />
                    </div>
                </div>
                <div>
                    <Label>
                        <T
                            keyName="presentation-sheet.aside.card.presentation-aspect-ratio.title"
                            defaultValue="גודל המצגת"
                        />
                    </Label>
                    <div className="mt-1">
                        <Input
                            disabled
                            className="!cursor-default !opacity-100 text-muted-foreground pe-9"
                            value={presentation.aspectRatio}
                        />
                    </div>
                </div>
            </div>
        </aside>
    );
}
