import { Disc, FolderOpen, Sparkles } from "lucide-react";
import { buttonVariants } from "@/components/ui/button";
import { cn } from "@/lib/utils";

const featuresList = [
    {
        icon: Sparkles,
        text: (
            <p>
                <b>יצירת תכנים עם AI</b> כדי לעזור לכם לדעת מאיפה להתחיל
            </p>
        ),
    },
    {
        icon: FolderOpen,
        text: (
            <p>
                <b>אחסון</b> פשוט ונגיש לכל התכנים שלכם שתוכלו לשתף בקלות
            </p>
        ),
    },
    {
        icon: Disc,
        text: (
            <p>
                <b>רקורדר</b> - כלי מתקדם ליצירה ועריכה של סרטונים
            </p>
        ),
    },
];

export default function PaymentWall() {
    return (
        <div className="flex-1 flex flex-col w-full justify-center p-4">
            <h2 className="text-3xl font-semibold">שדרגו את המנוי שלכם</h2>
            <p className="mt-2 text-lg text-gray-700">
                בידע תמצאו את כל הכלים שאתם צריכים להצלחה:
            </p>
            <div className="mt-6 flex flex-col gap-4">
                {featuresList.map((feature, idx) => (
                    <div key={idx} className="flex flex-row items-center gap-2">
                        <feature.icon className="size-6 text-primary" />
                        {feature.text}
                    </div>
                ))}
            </div>
            <a
                href="/pricing"
                className={cn(buttonVariants({ variant: "ai" }), "mt-6 w-full")}
            >
                לצפייה במסלולים
            </a>
            <p className="mt-2 text-sm text-muted-foreground text-center">
                אפשר לבטל את המנוי בכל זמן
            </p>
        </div>
    );
}
