import { client } from "@/lib/hono";
import toast from "@/lib/toast";
import { useQueryClient } from "@tanstack/react-query";
import { Dispatch, SetStateAction, useState } from "react";
import { Interaction } from "../lib/types";

interface UseSaveInteractionsProps {
    videoId: string;
    interactions: Interaction[];
    setReloadVideoKey: Dispatch<SetStateAction<number>>;
}

export const useSaveInteractions = ({
    videoId,
    interactions,
    setReloadVideoKey,
}: UseSaveInteractionsProps) => {
    const [loading, setLoading] = useState(false);
    const [changes, setChanges] = useState(false);

    const queryClient = useQueryClient();

    const saveChanges = async () => {
        setLoading(true);

        const res = await client.api.storage["video-interactions"][
            ":videoId"
        ].$post({
            param: { videoId },
            json: {
                interactions: interactions.map((i) => ({
                    ...i,
                    content: JSON.stringify(i.content),
                })),
            },
        });

        const resJson = await res.json();
        console.log({ resJson });

        if (!res.ok) {
            toast.error("אירעה שגיאה בניסיון שמירת אינטראקציות", {
                keyName: "toast.error.default",
            });
            setLoading(false);
            return;
        }

        queryClient.invalidateQueries({
            queryKey: ["get-interactions", videoId],
        });

        setReloadVideoKey(Date.now());
        toast.success("השינויים נשמרו בהצלחה!", {
            keyName: "toast.success.save-changes",
        });

        setLoading(false);
        setChanges(false);
    };

    return {
        loading,
        changes,
        setChanges,
        saveChanges,
    };
};
