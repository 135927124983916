import {
    DialogDescription,
    DialogHeader,
    DialogTitle,
} from "@/components/ui/dialog";
import { T } from "@tolgee/react";

interface StepHeaderProps {
    title: {
        keyName: string;
        defaultValue: string;
    };
    description?: {
        keyName: string;
        defaultValue: string;
    };
}

export default function StepHeader({ title, description }: StepHeaderProps) {
    return (
        <DialogHeader>
            <DialogTitle>
                <T keyName={title.keyName} defaultValue={title.defaultValue} />
            </DialogTitle>
            {description && (
                <DialogDescription>
                    <T
                        keyName={description.keyName}
                        defaultValue={description.defaultValue}
                    />
                </DialogDescription>
            )}
        </DialogHeader>
    );
}
