import { useQuery } from "@tanstack/react-query";
import { client } from "@/lib/hono";
import { InferResponseType } from "hono";

export type GetVideoResponseType = InferResponseType<
    (typeof client.api.storage.query.video)[":videoId"]["$get"],
    200
>;

export const useGetVideo = (enabled: boolean, videoId: string) => {
    const query = useQuery({
        enabled,
        queryKey: ["get-video", videoId],
        queryFn: async () => {
            const response = await client.api.storage.query.video[
                ":videoId"
            ].$get({
                param: {
                    videoId,
                },
            });

            if (!response.ok) {
                throw new Error("Failed to fetch video");
            }

            const { data } = await response.json();
            return { data };
        },
    });

    return query;
};
