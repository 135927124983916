import { MutableRefObject } from "react";

interface ErrorMessage {
    param: string;
    message: string;
}

interface ErrorResponse {
    messages: ErrorMessage[];
}

interface TransactionResponse {
    success: boolean;
    [key: string]: any;
}

interface Response {
    transaction_response?: TransactionResponse;
    [key: string]: any;
}

export const useCharge = (fieldsRef: MutableRefObject<any>) => {
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        chargeCcData();
    };

    const chargeCcData = () => {
        if (fieldsRef.current) {
            fieldsRef.current.charge(
                {
                    terminal_name:
                        process.env.NEXT_PUBLIC_TRANZILA_TERMINAL_NAME,
                    amount: "10",
                    tran_mode: "N", // 'N' for verify transaction
                    tokenize: true,
                    notify_url: `${process.env.NEXT_PUBLIC_SERVER_URL}/webhooks/payments/tranzilla`,
                },
                function (
                    err: ErrorResponse | null,
                    response: Response | null
                ) {
                    if (err) {
                        handleErrors(err);
                    }
                    if (response) {
                        parseResponse(response);
                    }
                }
            );
        }
    };

    const handleErrors = (error: ErrorResponse) => {
        error.messages.forEach(function (message: ErrorMessage) {
            const errorElement = document.getElementById(
                "errors_for_" + message.param
            );
            if (errorElement) {
                errorElement.textContent = message.message;
            }
        });
    };

    const parseResponse = (response: Response) => {
        // Handle the response from Tranzila
        if (
            response.transaction_response &&
            response.transaction_response.success
        ) {
            // Payment succeeded
            console.log("Payment successful:", response);
            alert("Payment successful!");
            // You can redirect or update the UI as needed
        } else {
            // Payment failed
            console.log("Payment failed:", response);
            alert("Payment failed. Please try again.");
        }
    };

    return { handleSubmit };
};
