import { useQuery } from "@tanstack/react-query";
import { client } from "@/lib/hono";
import { InferResponseType } from "hono";

export type GetPresentationResponseType = InferResponseType<
    (typeof client.api.storage.query.presentation)[":presentationId"]["$get"],
    200
>;

export const useGetPresentation = (
    enabled: boolean,
    presentationId: string
) => {
    const query = useQuery({
        enabled,
        queryKey: ["get-presentation", presentationId],
        queryFn: async () => {
            const response = await client.api.storage.query.presentation[
                ":presentationId"
            ].$get({ param: { presentationId } });

            if (!response.ok) {
                throw new Error("Failed to fetch presentationId");
            }

            const { data } = await response.json();
            return { data };
        },
    });

    return query;
};
