"use client";

import { Label } from "@/components/ui/label";
import { useInteractions } from "../../../hooks/interactionsProvider";
import StepHeader from "../../ui/stepHeader";
import { T } from "@tolgee/react";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import FormFields from "./formFields";
import { VideoInteractionType } from "@dev-xsiter/shared-schema";
import ContinueButton from "../../ui/continueButton";

export default function QuestionStep() {
    const { nextStep, selectedInteraction, updateInteraction } =
        useInteractions();

    if (!selectedInteraction) return <></>;

    const copy = getCopy(selectedInteraction.type);

    return (
        <div className="flex flex-col gap-6 w-full">
            <StepHeader
                title={{
                    keyName: copy.title.keyName,
                    defaultValue: copy.title.defaultValue,
                }}
                description={{
                    keyName: copy.description.keyName,
                    defaultValue: copy.description.defaultValue,
                }}
            />

            <div className="flex flex-col gap-2">
                <Label htmlFor="interactionQuestion">
                    <T
                        keyName={copy.questionLabel.keyName}
                        defaultValue={copy.questionLabel.defaultValue}
                    />
                </Label>
                <Textarea
                    id="interactionQuestion"
                    value={selectedInteraction?.content.question}
                    onChange={(e) =>
                        updateInteraction(selectedInteraction!.id, {
                            content: {
                                ...selectedInteraction?.content,
                                question: e.target.value,
                            },
                        })
                    }
                />
            </div>

            {selectedInteraction.type === "form" && <FormFields />}

            <ContinueButton />
        </div>
    );
}

const getCopy = (type: VideoInteractionType) => {
    if (type === "form") {
        return {
            title: {
                keyName: "interaction-modal.form.header.title",
                defaultValue: "טופס",
            },
            description: {
                keyName: "interaction-modal.form.header.description",
                defaultValue: "הזינו את הכותרת ובחרו את השדות",
            },
            questionLabel: {
                keyName: "interaction-modal.form-question.label",
                defaultValue: "כותרת",
            },
        };
    } else if (type === "rating") {
        return {
            title: {
                keyName: "interaction-modal.rating.header.title",
                defaultValue: "דירוג",
            },
            description: {
                keyName: "interaction-modal.rating.header.description",
                defaultValue: "הזינו את הכותרת שלכם",
            },
            questionLabel: {
                keyName: "interaction-modal.rating.label",
                defaultValue: "כותרת",
            },
        };
    } else {
        return {
            title: {
                keyName: "interaction-modal.question.header.title",
                defaultValue: "שאלה",
            },
            description: {
                keyName: "interaction-modal.question.header.description",
                defaultValue: "הזינו את השאלה שלכם",
            },
            questionLabel: {
                keyName: "interaction-modal.question.label",
                defaultValue: "שאלה",
            },
        };
    }
};
