import { GetPresentationResponseType } from "../../hooks/useGetPresentation";
import GeneralTab from "./tabs/general";
import Image from "next/image";

export default function PresentationSheetMain({
    presentation,
}: {
    presentation: GetPresentationResponseType["data"];
}) {
    return (
        <main className="flex flex-1 flex-col py-4 px-8 gap-4">
            <div className="relative aspect-video rounded-md border">
                <Image
                    className="rounded-md"
                    src={
                        presentation.thumbnailUrl ||
                        "/images/image-placeholder.png"
                    }
                    fill
                    alt="Presentation thumbnail"
                />
            </div>
            <GeneralTab presentation={presentation} />
            <div className="pb-8" />
        </main>
    );
}
