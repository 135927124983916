import toast from "@/lib/toast";
import { Interaction } from "../lib/types";
import { GetVideoResponseType } from "@/features/storage/sheets/hooks/useGetVideo";
import { Dispatch, SetStateAction } from "react";

export const useInteractionEdit = ({
    video,
    interactions,
    setInteractions,
    setSelectedInteraction,
    setChanges,
}: {
    video: GetVideoResponseType["data"];
    interactions: Interaction[];
    setInteractions: Dispatch<SetStateAction<Interaction[]>>;
    setSelectedInteraction: Dispatch<SetStateAction<Interaction | null>>;
    setChanges: Dispatch<SetStateAction<boolean>>;
}) => {
    const updateInteraction = (
        id: string,
        fieldsToUpdate: Partial<Interaction>
    ) => {
        if (
            fieldsToUpdate.timestamp &&
            (fieldsToUpdate.timestamp <= 0 ||
                (video.length && fieldsToUpdate.timestamp >= video.length))
        ) {
            toast.error("הזמן חייב להיות בתוך גבולות הסרטון", {
                keyName: "toast.error.interaction-timestamp-out-of-bounds",
            });
            return;
        }
        if (
            fieldsToUpdate.timestamp &&
            !!interactions.find(
                (i) => i.id !== id && i.timestamp === fieldsToUpdate.timestamp
            )
        ) {
            toast.error("הזמן שבחרת תפוס על ידי אינטראקציה אחרת", {
                keyName: "toast.error.interaction-timestamp-is-taken",
            });
            return;
        }

        setInteractions((prev) =>
            prev
                .map((i) => (i.id === id ? { ...i, ...fieldsToUpdate } : i))
                .sort((a, b) => a.timestamp - b.timestamp)
        );
        setSelectedInteraction((prev) =>
            prev && prev.id === id ? { ...prev, ...fieldsToUpdate } : prev
        );
        setChanges(true);
    };

    return { updateInteraction };
};
