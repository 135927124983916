"use client";

import { Button, buttonVariants } from "@/components/ui/button";
import {
    AlertDialog,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { LoaderCircle, Trash } from "lucide-react";
import { useState } from "react";
import { client } from "@/lib/hono";
import toast from "@/lib/toast";
import { T } from "@tolgee/react";
import { GetVideoResponseType } from "../../../hooks/useGetVideo";
import { useStorage } from "@/features/storage/useStorage";
import { useQueryClient } from "@tanstack/react-query";

interface DeleteVideoButtonProps {
    video: GetVideoResponseType["data"];
}

export default function DeleteVideoButton({ video }: DeleteVideoButtonProps) {
    const setOpen = useStorage((state) => state.setVideoSheetOpen);

    const [isPending, setIsPending] = useState(false);

    const queryClient = useQueryClient();

    const closeModal = () => {
        setOpen(false);
        setIsPending(false);
    };

    const submitHandler = async () => {
        setIsPending(true);

        const res = await client.api.storage.video[":videoId"].delete.$delete({
            param: {
                videoId: video.id,
            },
        });

        if (!res.ok) {
            const error = await res.text();
            console.log(error);
            toast.error("אירעה שגיאה בנסיון מחיקת הסרטון", {
                keyName: "toast.error.default",
            });
        } else {
            const data = await res.json();
            console.log(data);

            queryClient.invalidateQueries({ queryKey: ["get-storage-files"] });

            toast.success("הסרטון נמחק בהצלחה!", {
                keyName: "toast.success.delete-video",
            });
            closeModal();
        }
    };

    return (
        <AlertDialog>
            <AlertDialogTrigger>
                <div
                    className={buttonVariants({
                        variant: "secondary",
                        size: "sm",
                    })}
                >
                    <Trash className="size-3.5 me-2" />
                    <T
                        keyName="video-sheet.header.delete-btn"
                        defaultValue="מחיקה"
                    />
                </div>
            </AlertDialogTrigger>
            <AlertDialogContent className="sm:max-w-[380px]">
                <AlertDialogHeader>
                    <AlertDialogTitle>
                        <T
                            keyName="storage.delete-video-modal.header.title"
                            defaultValue="מחיקת סרטון"
                        />
                    </AlertDialogTitle>
                    <AlertDialogDescription>
                        <T
                            keyName="storage.delete-video-modal.header.description"
                            defaultValue="אתם בטוחים שתרצו למחוק את הסרטון: <b>{videoTitle}</b>? פעולה זו לא ניתנת לשחזור."
                            params={{
                                b: (chunks) => (
                                    <span className="font-semibold">
                                        {chunks}
                                    </span>
                                ),
                                videoTitle: video?.title || "",
                            }}
                        />
                    </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter className="flex flex-row gap-3">
                    <AlertDialogCancel
                        disabled={isPending}
                        className="flex justify-center items-center"
                    >
                        <T
                            keyName="storage.delete-video-modal.cancel-btn"
                            defaultValue="ביטול"
                        />
                    </AlertDialogCancel>
                    <Button
                        disabled={isPending}
                        onClick={() => submitHandler()}
                        variant="destructive"
                        className="flex justify-center items-center"
                    >
                        {isPending ? (
                            <LoaderCircle className="size-4 text-white animate-spin" />
                        ) : (
                            <T
                                keyName="storage.delete-video-modal.delete-btn"
                                defaultValue="מחיקה"
                            />
                        )}
                    </Button>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    );
}
