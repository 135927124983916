"use client";

import { useStorage } from "../../useStorage";
import { Sheet, SheetContent } from "@/components/ui/sheet";
import { useGetPresentation } from "../hooks/useGetPresentation";
import { LoaderCircle } from "lucide-react";
import PresentationSheetMain from "./components/main";
import PresentationSheetHeader from "./components/header/header";
import PresentationSheetAside from "./components/aside";

export default function PresentationSheet() {
    const [open, setOpen, options] = useStorage((state) => [
        state.presentationSheetOpen,
        state.setPresentationSheetOpen,
        state.presentationOptions,
    ]);

    const { isPending, data } = useGetPresentation(
        open && !!options.presentationId && !!options.libraryId,
        options.presentationId
    );
    const presentation = data?.data;

    return (
        <Sheet open={open} onOpenChange={(open) => setOpen(open)}>
            <SheetContent
                className="bg-muted !p-0 w-full sm:w-[80vw] sm:max-w-[1200px]"
                xClassName="sm:top-4 sm:-left-10 sm:flex sm:justify-center sm:items-center sm:rounded-full sm:bg-white sm:size-6"
            >
                {isPending || !presentation ? (
                    <div className="flex w-full h-full justify-center items-center">
                        <LoaderCircle className="size-6 animate-spin" />
                    </div>
                ) : (
                    <>
                        <PresentationSheetHeader presentation={presentation} />

                        <div className="flex overflow-y-auto h-[calc(100%-64px)] flex-col lg:flex-row">
                            <PresentationSheetAside
                                presentation={presentation}
                            />
                            <PresentationSheetMain
                                presentation={presentation}
                            />
                        </div>
                    </>
                )}
            </SheetContent>
        </Sheet>
    );
}
