import { useState } from "react";
import { interactionModalSteps } from "../lib/utils";
import { InteractionStep } from "../lib/types";

export const useInteractionModalSteps = () => {
    const [currentStep, setCurrentStep] =
        useState<InteractionStep>("time-and-type");

    const nextStep = () => {
        const stepIdx = interactionModalSteps.findIndex(
            (s) => s.id === currentStep
        );
        if (stepIdx < interactionModalSteps.length - 1) {
            setCurrentStep(interactionModalSteps[stepIdx + 1].id);
        }
    };

    const prevStep = () => {
        const stepIdx = interactionModalSteps.findIndex(
            (s) => s.id === currentStep
        );
        if (stepIdx > 0) {
            setCurrentStep(interactionModalSteps[stepIdx - 1].id);
        }
    };

    const moveToStep = (step: InteractionStep) => {
        setCurrentStep(step);
    };

    return { currentStep, setCurrentStep, nextStep, prevStep, moveToStep };
};
