import { Button, buttonVariants } from "@/components/ui/button";
import { T } from "@tolgee/react";
import { Pencil } from "lucide-react";
import { GetPresentationResponseType } from "../../../hooks/useGetPresentation";
import { Label } from "@/components/ui/label";
import DeletePresentationButton from "./deleteButton";
import DuplicatePresentationButton from "./duplicateButton";
import { useStorage } from "@/features/storage/useStorage";

export default function PresentationSheetHeader({
    presentation,
}: {
    presentation: GetPresentationResponseType["data"];
}) {
    const setOpen = useStorage((state) => state.setPresentationSheetOpen);

    return (
        <header className="bg-white px-8 flex flex-row h-16 w-full justify-between items-center border-b">
            <div className="truncate flex flex-col">
                <Label className="truncate text-base">
                    {presentation?.name ? (
                        <T
                            keyName="presentation-sheet.header.title"
                            defaultValue="מצגת: {presentationName}"
                            params={{
                                presentationName: presentation.name,
                            }}
                        />
                    ) : (
                        <T
                            keyName="presentation-sheet.header.title.placeholder"
                            defaultValue="מצגת ללא שם"
                        />
                    )}
                </Label>

                {presentation?.collection && (
                    <p className="truncate text-xs text-muted-foreground">
                        <T
                            keyName="presentation-sheet.header.subtitle"
                            defaultValue="תיקייה: {folderName}"
                            params={{
                                folderName: presentation.collection.name,
                            }}
                        />
                    </p>
                )}
            </div>

            <div className="flex flex-row gap-2">
                <DeletePresentationButton presentation={presentation} />
                <DuplicatePresentationButton presentation={presentation} />
                <a
                    onClick={() => {
                        setOpen(false);
                    }}
                    href={`/recorder/${presentation.id}`}
                    className={buttonVariants({
                        variant: "secondary",
                        size: "sm",
                    })}
                >
                    <Pencil className="size-3.5 me-2" />
                    <T
                        keyName="presentation-sheet.header.edit-btn"
                        defaultValue="עריכה"
                    />
                </a>
            </div>
        </header>
    );
}
