import { create } from "zustand";

type Step = "PaymentWall" | "PaymentForm";

interface PaymentsStore {
    currentStep: Step;
    paymentModalOpen: boolean;
    setPaymentModalOpen: (open: boolean, step: Step) => void;
}

export const usePayments = create<PaymentsStore>((set, get) => ({
    currentStep: "PaymentWall",
    paymentModalOpen: false,
    setPaymentModalOpen: (open: boolean, step: Step) =>
        set(() => ({ paymentModalOpen: open, currentStep: step })),
}));
