"use client";

import { Label } from "@/components/ui/label";
import { T } from "@tolgee/react";
import { useInteractions } from "../../../hooks/interactionsProvider";
import { Checkbox } from "@/components/ui/checkbox";

export default function InteractionSettingsField() {
    const { selectedInteraction, updateInteraction } = useInteractions();

    return (
        <div className="flex flex-col gap-2">
            <Label htmlFor="interactionSettings">
                <T
                    keyName="interaction-modal.settings.label"
                    defaultValue="הגדרות"
                />
            </Label>
            <div className="flex flex-col gap-2">
                <div className="flex items-center gap-x-3">
                    <Checkbox
                        checked={!!selectedInteraction?.repeatUntilCorrect}
                        onCheckedChange={(checked) =>
                            updateInteraction(selectedInteraction!.id, {
                                repeatUntilCorrect: !!checked,
                            })
                        }
                    />
                    <p className="text-sm font-normal text-gray-500">
                        <T
                            keyName="interaction-modal.settings.repeatUntilCorrect"
                            defaultValue="חובה לענות נכון כדי להמשיך"
                        />
                    </p>
                </div>
            </div>
        </div>
    );
}
