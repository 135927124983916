"use client";

import { buttonVariants } from "@/components/ui/button";
import { T } from "@tolgee/react";
import { Download, LoaderCircle } from "lucide-react";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { cn } from "@/lib/utils";
import { useState } from "react";
import { client } from "@/lib/hono";
import toast from "@/lib/toast";
import { usePayments } from "@/features/payments/hooks/usePayments";

export default function DownloadVideoButton({
    videoId,
    availableResolutions,
    videoReady,
}: {
    videoId: string;
    availableResolutions: string | null;
    videoReady: boolean;
}) {
    const [loading, setLoading] = useState(false);
    const setPaymentModalOpen = usePayments(
        (state) => state.setPaymentModalOpen
    );

    const resolutions = availableResolutions
        ? availableResolutions
              .split(",")
              .map((res) => parseInt(res.trim()))
              .sort((a, b) => a - b)
              .map((r) => r + "p")
        : [];

    const downloadVideo = async (resolution: string) => {
        if (!videoReady) {
            toast.error("יש לחכות שעיבוד הסרטון יסתיים", {
                keyName: "toast.error.wait-for-video-to-be-ready",
            });
            return;
        }

        setLoading(true);

        const res = await client.api.storage.video[":videoId"].download.$get({
            param: { videoId },
            query: { resolution },
        });

        if (!res.ok) {
            setLoading(false);

            if (res.status === 402) {
                setPaymentModalOpen(true, "PaymentWall");
            } else {
                toast.error("אירעה שגיאה", { keyName: "toast.error.default" });
            }

            return;
        }

        const data = await res.json();
        const { downloadUrl } = data.data;

        try {
            // Open the download in a new tab to bypass CORS restrictions
            window.open(downloadUrl, "_blank", "noopener,noreferrer");

            toast.success("ההורדה החלה!", {
                keyName: "toast.success.download-started",
            });
        } catch (error) {
            console.error("Download error:", error);
            toast.error("שגיאה בהורדה", {
                keyName: "toast.error.default",
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <DropdownMenu dir="rtl">
            <DropdownMenuTrigger disabled={loading}>
                <div
                    className={cn(
                        buttonVariants({ size: "sm", variant: "secondary" })
                    )}
                >
                    {loading ? (
                        <div className="w-[58px] flex justify-center items-center">
                            <LoaderCircle className="size-4 animate-spin" />
                        </div>
                    ) : (
                        <>
                            <Download className="size-3.5 me-2" />
                            <T
                                keyName="video-sheet.header.download-btn"
                                defaultValue="הורדה"
                            />
                        </>
                    )}
                </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
                <DropdownMenuLabel>
                    <T
                        keyName="video-sheet.header.download-btn.resolution-dropdown-menu-label"
                        defaultValue="בחרו רזולוציה"
                    />
                </DropdownMenuLabel>
                <DropdownMenuSeparator />
                {resolutions.map((r, idx) => (
                    <DropdownMenuItem
                        onSelect={() => downloadVideo(r)}
                        key={idx}
                    >
                        {r}
                    </DropdownMenuItem>
                ))}
            </DropdownMenuContent>
        </DropdownMenu>
    );
}
