"use client";

import { Dialog, DialogContent } from "@/components/ui/dialog";
import { usePayments } from "../../hooks/usePayments";
import Image from "next/image";
import PaymentWall from "./steps/paymentWall/paymentWall";
import PaymentForm from "./steps/paymentForm/paymentForm";

export default function PaymentsModal() {
    const [open, setOpen, currentStep] = usePayments((state) => [
        state.paymentModalOpen,
        state.setPaymentModalOpen,
        state.currentStep,
    ]);

    return (
        <Dialog open={open} onOpenChange={(open) => setOpen(open, currentStep)}>
            <DialogContent
                xPosition="top-2 right-2"
                className="p-4 max-w-[800px]"
            >
                <div className="flex w-full h-full flex-col md:flex-row-reverse gap-6">
                    <div className="flex-1">
                        <Image
                            className="h-full w-full"
                            src="/images/payments/modal-image.png"
                            alt="image"
                            width={500}
                            height={500}
                            quality={100}
                        />
                    </div>
                    {currentStep === "PaymentWall" && <PaymentWall />}
                    {currentStep === "PaymentForm" && <PaymentForm />}
                </div>
            </DialogContent>
        </Dialog>
    );
}
