import toast from "@/lib/toast";
import { Interaction } from "../lib/types";
import { v4 as uuid } from "uuid";
import { GetVideoResponseType } from "@/features/storage/sheets/hooks/useGetVideo";
import { Dispatch, SetStateAction } from "react";

export const useInteractionsList = ({
    video,
    interactions,
    setInteractions,
    setSelectedInteraction,
    setChanges,
    setInteractionModalOpen,
}: {
    video: GetVideoResponseType["data"];
    interactions: Interaction[];
    setInteractions: Dispatch<SetStateAction<Interaction[]>>;
    setSelectedInteraction: Dispatch<SetStateAction<Interaction | null>>;
    setChanges: Dispatch<SetStateAction<boolean>>;
    setInteractionModalOpen: Dispatch<SetStateAction<boolean>>;
}) => {
    const createInteraction = () => {
        setChanges(true);

        const newInteraction: Interaction = {
            id: uuid(),
            videoId: video.id,
            type: "open",
            timestamp: getAvailableTimestampAtTheEnd(),
            content: {
                question: "מה הצבע של הים?",
                answer: "כחול",
            },
            repeatUntilCorrect: false,
        };

        setInteractions((prev) =>
            [...prev, newInteraction].sort((a, b) => a.timestamp - b.timestamp)
        );

        setSelectedInteraction(newInteraction);
        setInteractionModalOpen(true);
    };

    const editInteraction = (id: string) => {
        const interaction = interactions.find((i) => i.id === id);
        if (!interaction) {
            toast.error("אינטראקציה לא נמצאה", {
                keyName: "toast.error.default",
            });
            return;
        }

        setSelectedInteraction(interaction);
        setInteractionModalOpen(true);
    };

    const deleteInteraction = (id: string) => {
        setInteractions((prev) => prev.filter((i) => i.id !== id));
        setChanges(true);
    };

    const duplicateInteraction = (id: string) => {
        const interactionToDuplicate = interactions.find((i) => i.id === id);
        if (!interactionToDuplicate) {
            toast.error("אינטראקציה לא נמצאה", {
                keyName: "toast.error.default",
            });
            return;
        }

        const newInteraction: Interaction = {
            ...interactionToDuplicate,
            id: uuid(),
            timestamp: getAvailableTimestampAtTheEnd(),
        };

        setInteractions((prev) =>
            [...prev, newInteraction].sort((a, b) => a.timestamp - b.timestamp)
        );
        setChanges(true);
    };

    const getInteractionTitle = (id: string) => {
        const interaction = interactions.find((i) => i.id === id);
        if (!interaction) {
            console.log("interaction not found in getInteractionTitle");
            return "";
        }

        return interaction.content.question;
    };

    const getAvailableTimestampAtTheEnd = () => {
        const sortedInteractions = interactions.sort(
            (a, b) => a.timestamp - b.timestamp
        );
        const lastInteractionTimestamp = sortedInteractions.length
            ? sortedInteractions[sortedInteractions.length - 1].timestamp
            : 0;

        return video.length
            ? Math.round((video.length - lastInteractionTimestamp) / 2)
            : 0;
    };

    return {
        createInteraction,
        editInteraction,
        deleteInteraction,
        duplicateInteraction,
        getInteractionTitle,
    };
};
