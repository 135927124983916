import { Card } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { T } from "@tolgee/react";
import { LoaderCircle } from "lucide-react";

export default function PendingCaptions() {
    return (
        <Card className="mt-4 flex flex-col w-full items-center justify-center p-12">
            <Label className="text-base">
                <T
                    keyName="video-sheet.pending-captions.title"
                    defaultValue="הכתוביות נמצאות בייצור"
                />
            </Label>
            <p className="mt-1 text-center text-sm text-muted-foreground">
                <T
                    keyName="video-sheet.pending-captions.subtitle"
                    defaultValue="תהליך הייצור יכול לקחת בין כמה דקות לשעה, הכתוביות יתווספו אוטומטית לסרטון ברגע שיהיו מוכנות."
                />
            </p>
            <LoaderCircle className="mt-4 size-4 animate-spin" />
        </Card>
    );
}
