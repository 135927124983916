import React, { useEffect, useRef } from "react";
import Script from "next/script";
import { Button } from "@/components/ui/button";
import CardField from "./cardField";
import { useCharge } from "./useCharge";
import { LockClosedIcon } from "@heroicons/react/24/solid";

declare global {
    interface Window {
        TzlaHostedFields: any;
    }
}

export default function PaymentForm() {
    const fieldsRef = useRef<any>(null);

    useEffect(() => {
        // Initialize the Hosted Fields after the script has loaded
        const initHostedFields = () => {
            if (!!fieldsRef.current) {
                return;
            } else if (typeof window.TzlaHostedFields !== "undefined") {
                console.log("creating hosted fields");

                fieldsRef.current = window.TzlaHostedFields.create({
                    sandbox: false,
                    fields: {
                        credit_card_number: {
                            selector: "#credit_card_number",
                            placeholder: "4580 4580 4580 4580",
                            tabindex: 1,
                        },
                        cvv: {
                            selector: "#cvv",
                            placeholder: "123",
                            tabindex: 2,
                        },
                        expiry: {
                            selector: "#expiry",
                            placeholder: "12/24",
                            version: "1",
                        },
                    },
                });
            } else {
                // Retry initialization if the script hasn't loaded yet
                setTimeout(initHostedFields, 100);
            }
        };

        initHostedFields();
    }, []);

    const { handleSubmit } = useCharge(fieldsRef);

    return (
        <div className="flex-1 flex flex-col w-full justify-center p-4">
            <h2 className="text-3xl font-semibold">שדרגו את המנוי שלכם</h2>
            <p className="mt-2 text-lg text-gray-700">
                בידע תמצאו את כל הכלים שאתם צריכים להצלחה:
            </p>

            <Script
                src="https://hf.tranzila.com/assets/js/thostedf.js"
                strategy="afterInteractive"
            />

            <form
                id="payment_form"
                onSubmit={handleSubmit}
                className="mt-6 flex flex-col w-full gap-4"
            >
                <CardField
                    id="credit_card_number"
                    label="מספר כרטיס"
                    className="flex flex-col w-full"
                />
                <div className="flex flex-row w-full gap-3">
                    <CardField
                        id="expiry"
                        label="תוקף"
                        className="flex flex-col w-2/3"
                    />
                    <CardField
                        id="cvv"
                        label="cvv"
                        className="flex flex-col w-1/3"
                    />
                </div>

                <Button variant="ai" type="submit" className="mt-6 w-full">
                    תשלום
                </Button>
            </form>

            <p className="mt-2 text-sm text-muted-foreground text-center w-full flex flex-row gap-1.5 justify-center items-center">
                <LockClosedIcon className="size-4" />
                <span>תשלום מאובטח</span>
            </p>
        </div>
    );
}
