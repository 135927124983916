import { useQuery } from "@tanstack/react-query";
import { client } from "@/lib/hono";
import { InferResponseType } from "hono";

export type GetInteractionsResponseType = InferResponseType<
    (typeof client.api.storage)["video-interactions"][":videoId"]["$get"],
    200
>;

export const useGetInteractions = (videoId: string) => {
    const query = useQuery({
        enabled: !!videoId,
        queryKey: ["get-interactions", videoId],
        queryFn: async () => {
            const response = await client.api.storage["video-interactions"][
                ":videoId"
            ].$get({ param: { videoId } });

            if (!response.ok) {
                throw new Error("Failed to fetch video interactions");
            }

            const { data } = await response.json();
            return { data };
        },
    });

    return query;
};
