"use client";

import { Button } from "@/components/ui/button";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import { T } from "@tolgee/react";
import { LoaderCircle, Sparkles } from "lucide-react";
import { Label } from "@/components/ui/label";
import { languages, SrclangType } from "@dev-xsiter/shared-schema";
import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import toast from "@/lib/toast";
import { client } from "@/lib/hono";
import { usePayments } from "@/features/payments/hooks/usePayments";

export default function GenerateCaptions({
    videoId,
    videoReady,
    lengthInSeconds,
}: {
    videoId: string;
    videoReady: boolean;
    lengthInSeconds: number;
}) {
    const [loading, setLoading] = useState(false);
    const [srclang, setSrclang] = useState<SrclangType>(languages[0].code);

    const queryClient = useQueryClient();
    const setPaymentModalOpen = usePayments(
        (state) => state.setPaymentModalOpen
    );

    const onClick = async (e: any) => {
        if (!videoReady) {
            toast.error("יש לחכות שעיבוד הסרטון יסתיים", {
                keyName: "toast.error.wait-for-video-to-be-ready",
            });
            return;
        }

        e.preventDefault();
        setLoading(true);

        const res = await client.api.storage.captions.generate.$post({
            json: {
                srclang,
                videoId,
                lengthInSeconds,
            },
        });

        const resJson = await res.json();
        console.log({ resJson });

        if (!res.ok) {
            if (res.status === 402) {
                setPaymentModalOpen(true, "PaymentWall");
            } else {
                toast.error("אירעה שגיאה בניסיון התחלת ייצור כתוביות", {
                    keyName: "toast.error.default",
                });
            }

            setLoading(false);
            return;
        }

        queryClient.invalidateQueries({
            queryKey: ["get-video", videoId],
        });

        setLoading(false);
    };

    return (
        <div className="mt-5 flex flex-col w-full">
            <Label className="">
                <T
                    keyName="video-sheet.generate-captions.title"
                    defaultValue="יצירת כתוביות"
                />
            </Label>
            <p className="mt-1 text-sm text-muted-foreground">
                <T
                    keyName="video-sheet.generate-captions.subtitle"
                    defaultValue="באיזה שפה מדברים בסרטון?"
                />
            </p>

            <div className="flex flex-row items-center mt-4 gap-4 w-full">
                <Select
                    value={srclang}
                    onValueChange={(value: SrclangType) => setSrclang(value)}
                    dir="rtl"
                >
                    <SelectTrigger>
                        <SelectValue />
                    </SelectTrigger>
                    <SelectContent>
                        {languages.map((l) => (
                            <SelectItem key={l.code} value={l.code}>
                                {l.label}
                            </SelectItem>
                        ))}
                    </SelectContent>
                </Select>
                <Button
                    variant="ai"
                    disabled={loading}
                    size="default"
                    onClick={onClick}
                    className="flex flex-row gap-2 items-center"
                >
                    {loading ? (
                        <LoaderCircle className="size-4 animate-spin" />
                    ) : (
                        <>
                            <T
                                keyName="vidoe-sheet.generate-captions.with-ai-btn"
                                defaultValue="יצירת כתוביות עם AI"
                            />
                            <Sparkles className="size-4" />
                        </>
                    )}
                </Button>
            </div>
        </div>
    );
}
