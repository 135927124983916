"use client";

import { Label } from "@/components/ui/label";
import { T } from "@tolgee/react";
import { useInteractions } from "../../../hooks/interactionsProvider";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { PlusCircle, Trash } from "lucide-react";
import {
    CloseQuestionInteractionContent,
    OpenQuestionInteractionContent,
    VideoInteractionType,
} from "@dev-xsiter/shared-schema";
import MultipleSelector from "@/components/ui/multiple-selector";
import { v4 as uuid } from "uuid";
import { useEffect } from "react";
import { interactionModalSteps } from "../../../lib/utils";

const MAX_OPTIONS = 4;

type ContentType =
    | OpenQuestionInteractionContent
    | CloseQuestionInteractionContent;

export default function Answers() {
    const { selectedInteraction, updateInteraction, currentStep, nextStep } =
        useInteractions();

    useEffect(() => {
        const step = interactionModalSteps.find((s) => s.id === currentStep);

        if (
            selectedInteraction &&
            step &&
            step.showForTypes &&
            !step.showForTypes.includes(selectedInteraction.type)
        ) {
            nextStep();
        }
    }, [currentStep, nextStep, selectedInteraction, selectedInteraction?.type]);

    if (!selectedInteraction) return <></>;

    const copy = getCopy(selectedInteraction.type);

    const answers = (selectedInteraction.content as ContentType)?.answers || [];

    const updateOption = (
        id: string,
        fieldsToUpdate: Partial<ContentType["answers"][0]>
    ) => {
        updateInteraction(selectedInteraction.id, {
            content: {
                ...selectedInteraction.content,
                answers: (
                    selectedInteraction.content as ContentType
                ).answers.map((o) =>
                    o.id === id ? { ...o, ...fieldsToUpdate } : o
                ),
            },
        });
    };

    const addOption = () => {
        updateInteraction(selectedInteraction.id, {
            content: {
                ...selectedInteraction.content,
                answers: [
                    ...answers,
                    {
                        id: uuid(),
                        text: "",
                        ...(selectedInteraction.type === "close" && {
                            correct: false,
                        }),
                    },
                ],
            },
        });
    };

    const deleteOption = (id: string) => {
        updateInteraction(selectedInteraction.id, {
            content: {
                ...selectedInteraction.content,
                answers: (
                    selectedInteraction.content as ContentType
                ).answers.filter((o) => o.id !== id),
            },
        });
    };

    const updateCorrectOptions = (correctIds: string[]) => {
        updateInteraction(selectedInteraction.id, {
            content: {
                ...selectedInteraction.content,
                answers: (
                    selectedInteraction.content as ContentType
                ).answers.map((o) => ({
                    ...o,
                    correct: correctIds.includes(o.id),
                })),
            },
        });
    };

    return (
        <>
            <div className="flex flex-col gap-2">
                <div className="flex flex-row justify-between items-center">
                    <Label>
                        <T
                            keyName={copy.keyName}
                            defaultValue={copy.defaultValue}
                        />
                    </Label>

                    {answers.length < MAX_OPTIONS && (
                        <Button
                            variant="link"
                            size="xs"
                            onClick={() => addOption()}
                            type="button"
                            className="flex flex-row gap-1.5 items-center text-black h-fit px-0"
                        >
                            <PlusCircle className="size-3.5" />
                            <T
                                keyName="interaction-modal.answers.add-btn"
                                defaultValue="הוספה"
                            />
                        </Button>
                    )}
                </div>

                <div className="flex flex-col gap-2">
                    {answers.map((o, idx) => (
                        <div key={idx} className="flex flex-row items-center">
                            <span className="w-5 text-sm text-muted-foreground">
                                {idx + 1}.
                            </span>
                            <Input
                                className="h-8 text-xs"
                                value={o.text}
                                onChange={(e) =>
                                    updateOption(o.id, {
                                        text: e.target.value,
                                    })
                                }
                            />
                            <button
                                onClick={() => deleteOption(o.id)}
                                type="button"
                            >
                                <Trash className="ms-2 text-destructive size-[16px]" />
                            </button>
                        </div>
                    ))}
                </div>
            </div>

            {selectedInteraction.type === "close" && (
                <div className="flex flex-col gap-2">
                    <Label>
                        <T
                            keyName="interaction-modal.answers-answers.label"
                            defaultValue="תשובות נכונות"
                        />
                    </Label>
                    <div dir="ltr">
                        <MultipleSelector
                            value={(
                                answers as CloseQuestionInteractionContent["answers"]
                            )
                                .filter((o) => o.correct)
                                .map((o) => ({
                                    label: (
                                        answers.findIndex(
                                            (op) => op.id === o.id
                                        ) + 1
                                    ).toString(),
                                    value: o.id,
                                }))}
                            onChange={(selectorOptions) => {
                                const correctIds = selectorOptions.map(
                                    (o) => o.value
                                );
                                updateCorrectOptions(correctIds);
                            }}
                            options={(
                                answers as CloseQuestionInteractionContent["answers"]
                            ).map((o) => ({
                                label: (
                                    answers.findIndex((op) => op.id === o.id) +
                                    1
                                ).toString(),
                                value: o.id,
                            }))}
                            className="h-9 min-h-0"
                            hideClearAllButton
                        />
                    </div>
                </div>
            )}
        </>
    );
}

const getCopy = (type: VideoInteractionType) => {
    if (type === "close") {
        return {
            keyName: "interaction-modal.close-question.answers.label",
            defaultValue: "אפשרויות תשובה",
        };
    } else {
        return {
            keyName: "interaction-modal.open-question.answers.label",
            defaultValue: "תשובות נכונות",
        };
    }
};
