"use client";

import PaymentsModal from "@/features/payments/components/paymentsModal/paymentsModal";
import StorageExplorerModal from "@/features/storage/explorer/StorageExplorerModal";
import PresentationSheet from "@/features/storage/sheets/presentationSheet/presentationSheet";
import VideoSheet from "@/features/storage/sheets/videoSheet/videoSheet";
import { useMountedState } from "react-use";

interface ModalsProviderProps {
    modalsFor: "platform";
}

export default function ModalsProvider({ modalsFor }: ModalsProviderProps) {
    const isMounted = useMountedState();

    if (!isMounted) return null;

    if (modalsFor === "platform") {
        return (
            <>
                <StorageExplorerModal />
                <VideoSheet />
                <PresentationSheet />
                <PaymentsModal />
            </>
        );
    }
}
