"use client";

import { Button, buttonVariants } from "@/components/ui/button";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog";
import { Copy, LoaderCircle } from "lucide-react";
import { useState } from "react";
import { client } from "@/lib/hono";
import toast from "@/lib/toast";
import { T } from "@tolgee/react";
import { GetPresentationResponseType } from "../../../hooks/useGetPresentation";
import { useStorage } from "@/features/storage/useStorage";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { useQueryClient } from "@tanstack/react-query";
import { cn } from "@/lib/utils";

interface DuplicatePresentationButtonProps {
    presentation: GetPresentationResponseType["data"];
}

export default function DuplicatePresentationButton({
    presentation,
}: DuplicatePresentationButtonProps) {
    const setOpen = useStorage((state) => state.setPresentationSheetOpen);

    const [isPending, setIsPending] = useState(false);

    const queryClient = useQueryClient();

    const closeModal = () => {
        setOpen(false);
        setIsPending(false);
    };

    const submitHandler = async (e: any) => {
        e.preventDefault();
        setIsPending(true);

        const newName = e.target.name.value as string;

        const res = await client.api.storage.presentation.duplicate.$post({
            json: {
                newName,
                projectIdToCopy: presentation.id,
            },
        });

        if (!res.ok) {
            const error = await res.text();
            console.log(error);
            toast.error("אירעה שגיאה בנסיון שכפול המצגת", {
                keyName: "toast.error.default",
            });
        } else {
            const data = await res.json();
            console.log(data);

            queryClient.invalidateQueries({ queryKey: ["get-storage-files"] });

            toast.success("המצגת שוכפלה בהצלחה!", {
                keyName: "toast.success.duplicate-presentation",
            });
            closeModal();
        }
    };

    return (
        <Dialog>
            <DialogTrigger>
                <div
                    className={cn(
                        buttonVariants({
                            variant: "secondary",
                            size: "sm",
                        })
                    )}
                >
                    <Copy className="size-3.5 me-2" />
                    <T
                        keyName="presentation-sheet.header.duplicate-btn"
                        defaultValue="שכפול"
                    />
                </div>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[380px]">
                <DialogHeader>
                    <DialogTitle>
                        <T
                            keyName="presentation.action-modals.duplicate.title"
                            defaultValue="שכפול מצגת"
                        />
                    </DialogTitle>
                    <DialogDescription className="">
                        <T
                            keyName="presentation.action-modals.duplicate.description"
                            defaultValue="בחרו שם למצגת החדשה"
                        />
                    </DialogDescription>
                </DialogHeader>
                <form onSubmit={submitHandler}>
                    <div className="w-full pb-6">
                        <Label className="flex w-full justify-start mb-2">
                            <T
                                keyName="presentation.action-modals.duplicate.name-input.label"
                                defaultValue="שם"
                            />
                        </Label>
                        <Input
                            id="name"
                            defaultValue={
                                presentation?.name
                                    ? `${presentation.name} (copy)`
                                    : ""
                            }
                            required
                        />
                    </div>
                    <DialogFooter>
                        <Button type="submit" disabled={isPending}>
                            {isPending ? (
                                <div className="flex w-full justify-center items-center">
                                    <LoaderCircle className="size-4 text-white animate-spin" />
                                </div>
                            ) : (
                                <T
                                    keyName="presentation.action-modals.duplicate.submit-btn"
                                    defaultValue="שכפול"
                                />
                            )}
                        </Button>
                    </DialogFooter>
                </form>
            </DialogContent>
        </Dialog>
    );
}
