"use client";

import { LoaderCircle } from "lucide-react";
import { useGetCaptions } from "../../../../hooks/useGetCaptions";
import GenerateCaptions from "./generateCaptions";
import EditCaptions from "./editCaptions";
import PendingCaptions from "./pendingCaptions";

export default function CaptionsTab({
    videoId,
    captionsId,
    setReloadVideoKey,
    videoReady,
    lengthInSeconds,
}: {
    videoId: string;
    captionsId: string;
    setReloadVideoKey: (key: number) => void;
    videoReady: boolean;
    lengthInSeconds: number;
}) {
    const { isPending, data } = useGetCaptions(captionsId);

    if (!captionsId) {
        return (
            <GenerateCaptions
                videoId={videoId}
                videoReady={videoReady}
                lengthInSeconds={lengthInSeconds}
            />
        );
    }

    if (isPending) {
        return (
            <div className="flex w-full py-24 justify-center items-center">
                <LoaderCircle className="size-5 animate-spin" />
            </div>
        );
    }

    if (data?.data?.srt) {
        return (
            <EditCaptions
                captionsId={data.data.id}
                srt={data.data.srt}
                setReloadVideoKey={setReloadVideoKey}
            />
        );
    }

    return <PendingCaptions />;
}
