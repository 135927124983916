"use client";

import { Label } from "@/components/ui/label";
import { cn } from "@/lib/utils";

export default function CardField({
    id,
    label,
    className,
}: {
    id: string;
    label: string;
    className?: string;
}) {
    return (
        <div className={cn(className)}>
            <Label className="mb-1.5" htmlFor={id}>
                {label}
            </Label>
            <div
                id={id}
                className="flex h-9 w-full rounded-md border border-input bg-background px-2.5 py-2 text-sm ring-offset-background placeholder:text-muted-foreground"
            ></div>
            <div
                id={`errors_for_${id}`}
                style={{ color: "red", marginTop: "4px" }}
            ></div>
        </div>
    );
}
