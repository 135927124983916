"use client";

import ModalsProvider from "./modalsProvider";

interface ProviderProps {
    children: React.ReactNode;
}

export const PlatformProviders = ({ children }: ProviderProps) => {
    return (
        <>
            <ModalsProvider modalsFor="platform" />

            {children}
        </>
    );
};
