"use client";

import { useInteractions } from "../../../hooks/interactionsProvider";
import StepHeader from "../../ui/stepHeader";
import { VideoInteractionType } from "@dev-xsiter/shared-schema";
import Answers from "./answers";
import ContinueButton from "../../ui/continueButton";

export default function AnswersStep() {
    const { selectedInteraction } = useInteractions();

    if (!selectedInteraction) return <></>;

    const copy = getCopy(selectedInteraction.type);

    return (
        <div className="flex flex-col gap-6 w-full">
            <StepHeader
                title={{
                    keyName: copy.title.keyName,
                    defaultValue: copy.title.defaultValue,
                }}
                description={{
                    keyName: copy.description.keyName,
                    defaultValue: copy.description.defaultValue,
                }}
            />

            <Answers />

            <ContinueButton />
        </div>
    );
}

const getCopy = (type: VideoInteractionType) => {
    return {
        title: {
            keyName: "interaction-modal.answers.header.title",
            defaultValue: "תשובות",
        },
        description: {
            keyName: "interaction-modal.answers.header.description",
            defaultValue: "הזינו את התשובות לשאלה שלכם",
        },
    };
};
