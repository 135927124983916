import { Button, buttonVariants } from "@/components/ui/button";
import { T } from "@tolgee/react";
import { Pencil, Trash } from "lucide-react";
import { GetVideoResponseType } from "../../../hooks/useGetVideo";
import { Label } from "@/components/ui/label";
import DeleteVideoButton from "./deleteButton";
import DownloadVideoButton from "./downloadButton";
import { cn } from "@/lib/utils";

export default function VideoSheetHeader({
    video,
    videoReady,
}: {
    video: GetVideoResponseType["data"];
    videoReady: boolean;
}) {
    return (
        <header className="bg-white px-8 flex flex-row h-16 w-full justify-between items-center border-b">
            <div className="truncate flex flex-col">
                <Label className="truncate text-base">
                    {video?.title ? (
                        <T
                            keyName="video-sheet.header.title"
                            defaultValue="סרטון: {videoTitle}"
                            params={{
                                videoTitle: video.title,
                            }}
                        />
                    ) : (
                        <T
                            keyName="video-sheet.header.title.placeholder"
                            defaultValue="סרטון ללא שם"
                        />
                    )}
                </Label>

                {video?.collection && (
                    <p className="truncate text-xs text-muted-foreground">
                        <T
                            keyName="video-sheet.header.subtitle"
                            defaultValue="תיקייה: {folderName}"
                            params={{
                                folderName: video.collection.name,
                            }}
                        />
                    </p>
                )}
            </div>

            <div className="flex flex-row gap-2">
                <DownloadVideoButton
                    videoId={video.id}
                    availableResolutions={video.availableResolutions}
                    videoReady={videoReady}
                />
                <a
                    href={`/edit-video/${video.id}`}
                    className={cn(
                        buttonVariants({ size: "sm", variant: "secondary" })
                    )}
                >
                    <Pencil className="size-3.5 me-2" />
                    <T
                        keyName="video-sheet.header.edit-btn"
                        defaultValue="עריכה"
                    />
                </a>
                <DeleteVideoButton video={video} />
            </div>
        </header>
    );
}
